import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authReducer(state = {
  isLoginPending: initialState.isLoginPending,
  loginError: initialState.loginError,
  isLoggedIn: initialState.isLoggedIn
}, action) {
  switch (action.type) {
    case types.SET_LOGIN_PENDING:
      return Object.assign({}, state, {
        isLoginPending: action.isLoginPending
      });
    case types.SET_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: action.isLoggedIn
      });
    case types.SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        loginError: action.loginError
      });
    default:
      return state;
  }
}
