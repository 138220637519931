import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({ text, style }) => (
  <div className={style ? 'alert ' + style : ''} role="alert">
    {text}
  </div>
)

Alert.propTypes = {
  text: PropTypes.string.isRequired
}

export default Alert
