import React, { Component } from 'react';

class StatDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errMsg: "",
      isLoaded: false,
      value: "",
      color: ""
    };
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    this.loadChart();
  }

  loadChart = async () => {
    const { metric, source, beginDate, endDate, decimals, textMapping } = this.props;


    //now load solar
    const URL = `/api/v1/metrics?m=${metric}&b=${beginDate}&e=${endDate}&s=${source}`;
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          return Promise.reject(new Error('error'));
        else
          return response.json()
      })
      .then(rtnData => {
        //need to put the data into state.
        //find the max date for each item, they are in desc order by date, so 1st is latest.
        let value = "";
        let color = "";
        if(rtnData && rtnData.length > 0) {
          value = rtnData[0].value;
        }

        if(decimals) {
          value = value.toFixed(decimals);
        }

        if(textMapping) {
          let text = "";
          if(textMapping[textMapping.length - 1].text) {
            text = textMapping[textMapping.length - 1].text;
          }
          if(textMapping[textMapping.length - 1].color) {
            color = textMapping[textMapping.length - 1].color;
          }
          for(let i = 0; i < textMapping.length; i++) {
            if(value > textMapping[i].value) {
              if(textMapping[i].text) {
                text = textMapping[i].text;
              }
              if(textMapping[i].color) {
                color = textMapping[i].color;
              }
            }
          }
          if(text.length > 0) {
            value = text;
          }
        }

        this.setState({
          value,
          color,
          isLoaded: true
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: true,
          errMsg: "Error: " + err.message
        });
      });
  }

  render() {
    const { title, unit } = this.props;
    const { isLoaded, value, color, error, errMsg} = this.state;

    let cardClass = "card";
    let headerClass = "card-header";
    if(color) {
      cardClass = `card border-${color}`;
      headerClass = `card-header text-white bg-${color} border-${color}`;
    }

    if (!isLoaded) {
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text text-center">
              <span className="spinner spinner-inverse spinner-sm">
                Loading...
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={cardClass}>
          <div className={headerClass}>{title}</div>
          <div className="card-block">
            <div className="card-text text-center">
              {error && <div className="alert alert-danger" role="alert">
                Error: {errMsg}
              </div>}
              <h1>{ value }{ unit }</h1>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default StatDisplay;
