import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Button
} from 'reactstrap';

class CustomNavbar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      usermenu: [{
        href: '/dashboard',
        text: 'Dashboard'
      }, {
        href: '/pihome',
        text: 'PiHome'
      }, {
        href: '/blog/create',
        text: 'Create Blog'
      }, {
        href: '/secret/create',
        text: 'Create Secret'
      }, {
        href: '/secret',
        text: 'Secrets'
      }, {
        href: '/log',
        text: 'Logs'
      }],
      publicMenu: [{
        href: '/covid',
        text: 'Covid ML'
      }, {
        href: '/stream',
        text: 'Stream'
      }]
    };

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.dispatch(logout())
  }

  render() {
    const { isLoginSuccess } = this.props;
    const { usermenu, publicMenu } = this.state;
    let link;
    if (isLoginSuccess) {
      link = <Button color="link" className="nav-link" onClick={(e) => this.handleLogout(e)}>Logout</Button>
    } else {
      link = <Link className="nav-link" to="/login">Login</Link>
    }
    return (
      <Navbar color="dark" dark expand="md">
        <Link className="navbar-brand" to="/"><clr-icon shape="home"></clr-icon> SimpsonHouse.hopto.org</Link>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {isLoginSuccess &&
              usermenu.map((item) => (
                <NavItem key={item.href}>
                  <Link className="nav-link" to={item.href}>{item.text}</Link>
                </NavItem>
              ))
            }
            {publicMenu.map((item) => (
              <NavItem key={item.href}>
                <Link className="nav-link" to={item.href}>{item.text}</Link>
              </NavItem>
            ))}
            <NavItem>
              {link}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

CustomNavbar.propTypes = {

};

function mapStateToProps(state, ownProps) {
  return {
    isLoginSuccess: !!state.auth.isLoggedIn
  };
}

export default connect(mapStateToProps)(CustomNavbar);
