import React, { Component } from 'react';
import { connect } from 'react-redux';
import PreviewAlbum from './PreviewAlbum';
import { Link } from "react-router-dom";

class ShowAlbum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: false,
      album: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.album && !this.state.isLoaded) {
      //load the album
      let URL = `/api/photo`;
      fetch(URL, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET"
      })
        .then(response => {
          if (response.status === 401) {
            //redirect to login
            this.props.dispatch(logout())
          } else {
            if (!response.ok) {
              this.setState({
                isLoaded: true,
                error: true
              });
              return Promise.reject(new Error('error'));
            } else
              return response.json()
          }
        })
        .then(rtnData => {
          const albums = rtnData.map((a) => {
            return {
              ...a,
              date: new Date(a.footer)
            }
          });
          const album = albums.filter((a) => a.id == this.props.match.params.album);
          if (album.length == 1) {
            this.setState({
              album: album[0],
              isLoaded: true
            });
          } else {
            this.setState({
              isLoaded: true,
              error: true
            })
          }
        })
        .catch((err) => {
          this.setState({
            isLoaded: true,
            error: true
          });
        });
    }
  }

  clickPhoto(url) {
    window.location.href = "https://simpsonhouse.hopto.org" + url;
  }

  render() {
    const { isLoaded, album, error } = this.state;
    console.log(this.state);

    if (!isLoaded) {
      return (
        <div className="row justify-content-center">
          <div className="col">
            <span className="spinner spinner-inverse">
              Loading...
            </span>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <h2>Error on loading album with id {this.props.match.params.album}</h2>
      )
    } else if (album) {
      return (
        <span>
          <h3>{album.description}</h3>
          <h4>Number of photos: {album.items.length}</h4>
          <div className="row">
            {album.items.map((i) => (
              <div className="col-sm-2 pad-top" key={i}>
                <div className="card">
                  <a href={`/api/photo/${album.id}/${i}`} target="_blank">
                    <img src={`/api/photo/${album.id}/${i}`} className="card-img-top" alt="Image preview" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </span>
      );
    } else {
      return (
        <h2>Not sure what happened here</h2>
      )
    }
  }
}

export default ShowAlbum;