import React, { Component } from 'react';

class GuageDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errMsg: "",
      isLoaded: false,
      value: 0.0,
    };
    this.loadChart = this.loadChart.bind(this);
  }

  componentDidMount() {
    this.loadChart();
  }

  loadChart = async () => {
    const { metric, beginDate, endDate, min, sumSources } = this.props;


    //now load solar
    const URL = `/api/v1/metrics?m=${metric}&b=${beginDate}&e=${endDate}`;
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          return Promise.reject(new Error('error'));
        else
          return response.json()
      })
      .then(rtnData => {
        //need to put the data into state.
        //find the max date for each item, they are in desc order by date, so 1st is latest.
        let value = min;
        if (rtnData && rtnData.length > 0) {
          if (sumSources) {
            value = 0;
            const sources = {};
            for (let i = 0; i < rtnData.length; i++) {
              if (!sources[rtnData[i].source]) {
                value += rtnData[i].value;
                sources[rtnData[i].source] = rtnData[i].value;
              }
            }
            if (value < min) {
              value = min;
            }
          } else {
            value = rtnData[0].value;
          }
        }

        this.setState({
          value,
          isLoaded: true
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: true,
          errMsg: "Error: " + err.message
        });
      });
  }

  render() {
    const { title, min, max, unit, progressStyle } = this.props;
    const { isLoaded, value, error, errMsg } = this.state;

    if (!isLoaded) {
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text text-center">
              <span className="spinner spinner-inverse spinner-sm">
                Loading...
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      const percent = ((value - min) / (max - min)) * 100.0;
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text text-center">
              {error && <div class="alert alert-danger" role="alert">
                Error: {errMsg}
              </div>}
              <h2>{`${value.toFixed(1)} ${unit}`}</h2>
              <div className="progress">
                <div className={`progress-bar progress-bar-striped ${progressStyle}`} role="progressbar" style={{ width: `${percent.toFixed(2)}%` }} aria-valuenow={percent} aria-valuemin={0} aria-valuemax={100}>{percent.toFixed(2)}%</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default GuageDisplay;