const URL = `/api/blog/series`;

function create(series) {
  //
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(series)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //apply date items.
        result.created = new Date(result.created);
        result.modified = new Date(result.modified);
        resolve(result);
      }, (error) => {
        reject(error);
      });
  });
}

function getAll() {
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

function remove(id) {
  //
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "DELETE"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //need to set result based on number modified.
        resolve(true);
      })
      .catch((err) => reject(err));
  });
}

function update(series) {
  //
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${series.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "PUT",
      body: JSON.stringify(series)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //apply date items.
        result.created = new Date(result.created);
        result.modified = new Date(result.modified);
        resolve(result);
      })
      .catch((err) => reject(err));
  });
}

export { getAll, remove, create, update }