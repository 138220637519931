import React, { Component } from 'react';
import { connect } from 'react-redux';
import PreviewAlbum from './PreviewAlbum';
import { Link } from "react-router-dom";
import ShowAlbum from './ShowAlbum';

class ListPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      albums: [],
      selectedId: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.album) {
      this.setState({
        selectedId: this.props.match.params.album
      });
    } else {
      this.setState({
        selectedId: null
      })
    }

    if (!this.state.isLoaded) {
      let URL = `/api/photo`;
      fetch(URL, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET"
      })
        .then(response => {
          if (response.status === 401) {
            //redirect to login
            this.props.dispatch(logout())
          } else {
            if (!response.ok) {
              this.setState({
                isLoaded: true,
                error: true
              });
              return Promise.reject(new Error('error'));
            } else
              return response.json()
          }
        })
        .then(rtnData => {
          this.setState({
            albums: rtnData.map((a) => {
              return {
                ...a,
                date: new Date(a.footer)
              }
            }),
            isLoaded: true
          });
        })
        .catch((err) => {
          this.setState({
            isLoaded: true,
            error: true
          });
        });
    }
  }

  // clickAlbum(selectedId) {
  //   //update url
  //   //window.history.replaceState(null, "Photo Album " + selectedId, "/photo-share/" + selectedId);
  //   window.history.pushState({}, "", "/photo-share/" + selectedId);

  //   this.setState({
  //     isLoaded: true,
  //     error: false,
  //     selectedId: selectedId
  //   });
  // }

  // clickPhoto(url) {
  //   window.location.href = "https://simpsonhouse.hopto.org" + url;
  // }

  // clickBack() {
  //   this.setState({
  //     isLoaded: true,
  //     error: false,
  //     selectedId: null
  //   });
  // }

  render() {
    const { isLoaded, albums } = this.state;
    if (!isLoaded) {
      return (
        <div className="row justify-content-center">
          <div className="col">
            <span className="spinner spinner-inverse">
              Loading...
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h3>Albums</h3>
          <div className="row">
            {albums.map((a) => (
              <PreviewAlbum album={a} key={a.id} />
            ))}
          </div>
        </div>
      );
    }
  }
}

export default ListPhotos;
