import * as types from './actionTypes';
import { getAll } from '../service/category';

export function loadBlogCategories() {
  return function (dispatch) {
    return getAll("").then(blogCategories => {
      dispatch(loadBlogCategoriesSuccess(blogCategories));
    }).catch(error => {
      throw (error);
    });
  };
}

export function loadBlogCategoriesSuccess(blogCategories) {
  return { type: types.LOAD_BLOG_CATEGORIES_SUCCESS, blogCategories };
}
