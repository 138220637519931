/*
 * other constants
 */

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

/*
 * action creators
 */

export function addAlertError(text) {
  return { type: ALERT_ERROR, text }
};

export function addAlertSuccess(text) {
  return { type: ALERT_SUCCESS, text }
};
