import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import CustomNavbar from './Navbar/CustomNavbar';
import AlertDisplay from '../containers/AlertDisplay';
import Login from '../components/login/Login';
import Dashboard from '../containers/Dashboard';
import Blog from '../components/blog/Blog';
import { connect } from 'react-redux';
import CreateBlog from './blog/CreateBlog';
import EditBlog from './blog/EditBlog';
import CreateSecret from './secrets/CreateSecret';
import ListSecrets from './secrets/ListSecrets';
import ListLogs from './logs/ListLogs';
import NotFound from './NotFound';
import BlogList from '../components/blog/BlogList';
import Antelope from "./antelope/Antelope";
import Draw from './draw/Draw';
import ChartPage from '../containers/ChartPage';
import Covid from './covid/Covid';
import Temp from './hack/Temp';
import pihome from '../components/pihome/pihome';
import Dow from './dow/Dow';
import ListStream from './stream/ListStream';
import StreamDetails from './stream/StreamDetails';
import '../App.css';
import { checkSessionApi } from '../service/auth';
import ListPhotos from './photo-share/ListPhotos';
import ShowAlbum from './photo-share/ShowAlbum';

const App = ({ isLoginSuccess }) => (
  <div className="main-container">
    <Router>
      <CustomNavbar />
      <div className="container-fluid">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/blog/create" component={CreateBlog} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/blog/edit/:id" component={EditBlog} isLoginSuccess={isLoginSuccess} />
          <Route path="/blog/:title" component={Blog} />
          <PrivateRoute path="/dashboard" component={Dashboard} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/secret/create" component={CreateSecret} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/secret/edit/:id" component={CreateSecret} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/secret" component={ListSecrets} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/log" component={ListLogs} isLoginSuccess={isLoginSuccess} />
          <PrivateRoute path="/pihome" component={pihome} isLoginSuccess={isLoginSuccess} />
          <Route path="/antelope" component={Antelope} />
          <Route path="/draw" component={Draw} />
          <Route path="/chart" component={ChartPage} />
          <Route path="/covid" component={Covid} />
          <Route path="/leftover" component={Dow} />
          <Route path="/stream/:id" component={StreamDetails} />
          <Route path="/stream" component={ListStream} />
          <Route path="/hack" component={Temp} />
          <Route exact path="/photo-share" component={ListPhotos} />
          <Route path="/photo-share/:album" component={ShowAlbum} />
          <Route component={NotFound} />
        </Switch>
      </div>

      <AlertDisplay />
    </Router>
  </div >
);

function PrivateRoute({ component: Component, isLoginSuccess, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isLoginSuccess ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

function Home() {
  return <div><div className="App-header">
    <h2>Welcome</h2>
  </div>
    <BlogList />
  </div>;
}


function mapStateToProps(state, ownProps) {
  //console.log(state);
  return {
    isLoginSuccess: !!state.auth.isLoggedIn
  };
}

export default connect(mapStateToProps)(App);
