import React from 'react';

const PihomeControl = ({ id, title, image, state, endDate, onClick }) => (
  <div className={"card " + (state ? 'border-success' : '')} >
    <div className="card-block stretched-link text-decoration-none" onClick={onClick} role="button">
      {image && <img src={image} className="card-img-top" alt="..." />}
      <div className="card-body">
        <p className="card-text">{title}</p>
      </div>
      {endDate &&
        <div className="card-footer">
          <small className="text-muted">{endDate.toLocaleString()}</small>
        </div>
      }
    </div>
  </div>
)

export default PihomeControl;