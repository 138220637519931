import React from 'react';
import Chart from './Chart';

const TempChart = () => {
  const title = "Temperature";
  const xLabel = "Date";
  const yLabel = "Temp (F)";
  const metric = "temperature";
  const endDate = new Date().getTime();
  const beginDate = endDate - 86400000;

  return (
    <Chart
        title={title}
        xLabel={xLabel}
        yLabel={yLabel}
        metric={metric}
        beginDate={beginDate}
        endDate={endDate} />
  );
};

export default TempChart;
