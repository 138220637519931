import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dowReducer(state = initialState.dow, action) {
  switch (action.type) {
    case types.LOAD_DOW_LEFTOVER_SUCCESS:
      return {
        ...state.dow,
        leftovers: action.leftovers
      };
    default:
      return state;
  }
}
