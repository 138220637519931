import React from 'react';
import LineChart from '../chart/LineChart';

const LineChartCard = ({ title, xLabel, yLabel, datasets, labels, legend, options }) => {
  return (
    <div className="card">
      <div className="card-header">{title}</div>
      <div className="card-block">
        <div className="card-text">
          <LineChart
            labels={labels}
            datasets={datasets}
            xLabel={xLabel}
            yLabel={yLabel}
            legend={legend}
            options={options} />
        </div>
      </div>
    </div>
  );
};

export default LineChartCard;