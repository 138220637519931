const URL = `/api/v1/secret`;

function create(secret) {
  //
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(secret)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //apply date items.
        result.created = new Date(result.created);
        result.modified = new Date(result.modified);
        result.isLink = result.href && (result.href.startsWith("http://") || result.href.startsWith("https://"));
        resolve(result);
      }, (error) => {
        reject(error);
      });
  });
}

function getAll() {
  return new Promise((resolve, reject) => {
    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //setup date objects.
        const modData = rtnData.map((item) => {
          item.created = new Date(item.created);
          item.modified = new Date(item.modified);
          item.isLink = item.href && (item.href.startsWith("http://") || item.href.startsWith("https://"));
          return item;
        })
        resolve(modData);
      })
      .catch((err) => reject(err));
  });
}

function remove(id) {
  //
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "DELETE"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then(rtnData => {
        //return must have id field to be filtered from list
        resolve({
          id: id
        });
      })
      .catch((err) => reject(err));
  });
}

function update(secret) {
  //
  return new Promise((resolve, reject) => {
    fetch(`${URL}/${secret.id}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "PUT",
      body: JSON.stringify(secret)
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else
          return response.json()
      })
      .then((result) => {
        //result is just the id of the secret
        //apply date items.
        //result.created = new Date(result.created);
        secret.modified = new Date();
        secret.isLink = secret.href && (secret.href.startsWith("http://") || secret.href.startsWith("https://"));
        resolve(secret);
      })
      .catch((err) => reject(err));
  });
}

export { getAll, remove, create, update }
