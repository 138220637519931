import React, { PureComponent } from 'react';
import Chart from 'chart.js';

class BarChart extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(nextProps) {
    if(nextProps.datasets !== this.props.datasets)
      this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { datasets, labels, xLabel, yLabel, legend } = this.props;

    if (this.state && this.state.myChart && typeof this.state.myChart !== "undefined") {
      this.state.myChart.destroy();
    }

    let legendOptions = {}
    if(legend) {
      legendOptions = {
        display: true,
        labels: {
          fontColor: '#666666'
        }
      } 
    } else {
      legendOptions = {
        display: false
      }
    }

    const myChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: xLabel,
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc"
            },
            gridLines: { color: "#cccccc" }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: yLabel,
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc",
              beginAtZero: true
            },
            gridLines: { color: "#666666" }
          }]
        },
        legend: legendOptions
      }
    });

    this.setState({
      myChart: myChart
    });
  }

  render() {
    return (
      <div>
        <canvas
          ref={this.chartRef}
        />
      </div>
    )
  }
}

export default BarChart;
