import * as types from './actionTypes';
import { create, remove, getAll, update } from '../service/series';

export function loadSeries() {
  return function (dispatch) {
    return getAll()
      .then(series => {
        dispatch(loadSeriesSuccess(series));
      }).catch(error => {
        throw (error);
      });
  };
}

export function removeSeries(id) {
  return function (dispatch) {
    return remove(id)
      .then(result => {
        dispatch(deleteSeriesSuccess(id));
      }).catch(error => {
        throw (error);
      })
  }
}

export function createSeries(series) {
  return function (dispatch) {
    return create(series).then(response => {
      dispatch(createSeriesSuccess(response));
      return response;
    }).catch(error => {
      throw (error);
    });
  };
}

export function updateSeries(series) {
  return function (dispatch) {
    return update(series).then(response => {
      dispatch(updateSeriesSuccess(response));
    }).catch(error => {
      throw (error);
    });
  };
}

export function loadSeriesSuccess(series) {
  return { type: types.LOAD_SERIES_SUCCESS, series };
}

export function updateSeriesSuccess(series) {
  return { type: types.UPDATE_SERIES_SUCCESS, series };
}

export function createSeriesSuccess(series) {
  return { type: types.CREATE_SERIES_SUCCESS, series };
}

export function deleteSeriesSuccess(id) {
  return { type: types.DELETE_SERIES_SUCCESS, id };
}
