import React from 'react';
import Chart from './Chart';

const GarageChart = () => {
  const title = "Garage";
  const xLabel = "Date";
  const yLabel = "Distance (feet)";
  const metric = "distance";
  const endDate = new Date().getTime();
  const beginDate = endDate - 86400000;
  const steppedLine = 'after';

  return (
    <Chart
        title={title}
        xLabel={xLabel}
        yLabel={yLabel}
        metric={metric}
        beginDate={beginDate}
        endDate={endDate}
        steppedLine={steppedLine} />
  );
};

export default GarageChart;
