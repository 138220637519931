//const URL = `/api/v1/blog/categories`;

function getAll() {
  return new Promise((resolve, reject) => {
    /*fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          throw response.statusText;
        else if(response.status >= 200 && response.status <= 400)
          return response.json();
        else
          reject({
            status: response.status
          })
      }, (error) => {
        console.error(error);
        reject(error);
      })
      .then(rtnData => {
        //temp categories
        rtnData = [{
          id: undefined,
          text: ''
        }, {
          id: 1,
          text: 'Troubleshooting'
        }, {
          id: 2,
          text: 'Kubernetes'
        }, {
          id: 3,
          text: 'Docker'
        }];
        resolve(rtnData);
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("here");
          console.error(error);
          throw (error);
          reject(error);
        })
      .catch(error => reject(error));*/
    //temp categories
    let rtnData = [{
      id: undefined,
      text: ''
    }, {
      id: 1,
      text: 'Troubleshooting'
    }, {
      id: 2,
      text: 'Kubernetes'
    }, {
      id: 3,
      text: 'Docker'
    }];
    resolve(rtnData);
  });
}

export { getAll }