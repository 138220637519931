import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as pihomeActions from '../../actions/pihomeActions';
import PihomeControl from './PihomeControl';
import { reboot } from '../../service/pihome';

class pihome extends Component {
  constructor(props) {
    super(props);

    this.flipSwitch = this.flipSwitch.bind(this);
    this.reboot = this.reboot.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadPihome();
    this.props.actions.loadPihomeStatus();
  }

  flipSwitch(item) {
    const payload = { ...item };
    payload.toState = !item.state;
    this.props.actions.updatePihome(payload);
  }

  reboot(id) {
    console.log(id);
    reboot(id)
      .then((status) => {
        console.log(status);
      })
      .catch((err) => {
        console.error(err);
      })
  }

  render() {
    const { pihome } = this.props;
    return (
      <div>
        <h2>PiHome</h2>
        <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3 row-cols-sm-3">
          {pihome.map((item) => (
            <div key={item.id} className="col mb-4">
              <PihomeControl
                id={item.id}
                state={item.state}
                title={item.title}
                onClick={() => this.flipSwitch(item)}
                endDate={item.endDate}
                image={item.image}
              />
            </div>
          ))}
        </div>
        <h3>Device Restart?</h3>
        <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3 row-cols-sm-3">
          <div className="col mb-4">
            <button className='btn btn-danger' onClick={() => this.reboot(1)}>PiGarage</button>
          </div>
          <div className="col mb-4">
            <button className='btn btn-danger' onClick={() => this.reboot(2)}>SprinkPi</button>
          </div>
          <div className="col mb-4">
            <button className='btn btn-danger' onClick={() => this.reboot(3)}>UpstairsPi</button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    pihome: state.pihome.sort((a, b) => ('' + a.title).localeCompare(b.title))
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(pihomeActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(pihome);