import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as blogActions from '../../actions/blogActions';
import NotFound from '../NotFound';
import ReactMarkdown from 'react-markdown';
import './Blog.css';
import { Link } from "react-router-dom";
import rehypeRaw from 'rehype-raw'

class Blog extends Component {
  componentDidMount() {
    if (this.props.blog.id) {
      const blogId = this.props.blog.id;
      if (!isNaN(blogId) && !this.props.blog.markdown) {
        this.props.actions.loadBlogDetails(blogId);
      }
    }
  }

  render() {
    const { blog, series, found, loading } = this.props;
    if (loading) {
      return (
        <div> Loading...</div>
      )
    } else if (!found) {
      return (
        <NotFound />
      )
    } else if (!blog.markdown) {
      const blogId = parseInt(this.props.blog.id);
      if (!isNaN(blogId) && !this.props.blog.markdown) {
        this.props.actions.loadBlogDetails(blogId);
      }
      return (
        <div> Loading...</div>
      )
    } else {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h3>Series: {blog.series}</h3>
              <ol>
                {series.map((item) => (
                  <li key={item.id}>
                    {item.id !== blog.id && <Link to={`/blog/${item.title}`}>{item.title}</Link>}
                    {item.id === blog.id && <span>{item.title}</span>}
                  </li>
                ))}
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-lg text-center">
              {blog.images.map((img) => (
                <img key={img} src={img} className="App-logo" alt="logo" />
              ))}
              <h2>{blog.title}</h2>
              <small className="text-muted">{blog.modified.toLocaleDateString()}</small> - <small className="text-muted">© Copyright {(blog.modified.getYear() + 1900)} <a href={blog.linkedin} target="_blank" rel="noopener noreferrer">{blog.firstname} {blog.lastname}</a></small><br />
            </div>
          </div>
          <div>
            {blog.markdown &&
              <ReactMarkdown rehypePlugins={[rehypeRaw]} children={blog.markdown}></ReactMarkdown>
            }
          </div>
        </div>
      );
    }
  }
}

/*Blog.propTypes = {
  blog: PropTypes.object.isRequired,
};*/

function mapStateToProps(state, ownProps) {
  let blog = undefined;
  const blogTitle = ownProps.match.params.title;
  if (state.blogs.length > 0) {
    let series = [];
    if (blogTitle) {
      //console.log(blogTitle);
      blog = Object.assign({}, state.blogs.find(item => item.title === blogTitle));
      //console.log(blog);
      if (blog.id) {
        let series_list = state.blogs.filter(item => item.seriesCreated.getTime() === blog.seriesCreated.getTime());
        series_list.sort((a, b) => a.created.getTime() - b.created.getTime());
        series = series_list.map((item) => {
          return {
            id: item.id,
            title: item.title
          };
        });
      }
    }
    if (blog && blog.id) {
      return {
        blog: blog,
        series: series,
        found: true,
        loading: false
      };
    } else {
      return {
        blog: {},
        series: series,
        found: false,
        loading: false
      };
    }
  } else {
    return {
      blog: {},
      series: [],
      loading: true
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(blogActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);