export default {
  secrets: [],
  blogs: [],
  isLoginPending: false,
  loginError: null,
  isLoggedIn: false,
  series: [],
  blogCategories: [],
  pihome: [],
  dow: {
    leftovers: []
  },
  stream: []
}
