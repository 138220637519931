import { connect } from 'react-redux'
import Alert from '../components/Alert'

const mapStateToProps = (state, ownProps) => {
  return {
    text: state.alerts.text,
    style: state.alerts.style
  }
}

const AlertDisplay = connect(
  mapStateToProps
)(Alert)

export default AlertDisplay;
