import React from 'react';

const ChartForm = ({ onSubmit, handleChange, chart }) => (
  <div className="container">
    <form>
      <div className="form-group row">
        <label htmlFor="titleText" className="col-form-label col-12 col-md-2">Title</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="title" placeholder="Title" id="idTitle" value={chart.title} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="xLabelText" className="col-form-label col-12 col-md-2">X Axis Label</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="xLabel" placeholder="x axis label" id="idxLabel" value={chart.xLabel} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="yLabelText" className="col-form-label col-12 col-md-2">Y Axis Label</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="yLabel" placeholder="y axis label" id="idyLabel" value={chart.yLabel} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="metricText" className="col-form-label col-12 col-md-2">Metric</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="metric" placeholder="metric" id="idMetric" value={chart.metric} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="endDateText" className="col-form-label col-12 col-md-2">End Date</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="endDate" placeholder="epoch time" id="idEndDate" value={chart.endDate} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="beginDateText" className="col-form-label col-12 col-md-2">Start Date</label>
        <div className=" col-12 col-md-10">
          <input className="form-control" type="text" name="beginDate" placeholder="epoch time" id="idBeginDate" value={chart.beginDate} onChange={handleChange} />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-12 col-md-2">Timespan</label>
        <div className="col-12 col-md-10">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-secondary active">
              <input type="radio" name="options" id="m" checked onChange={handleChange} /> 5 min
            </label>
            <label class="btn btn-secondary">
              <input type="radio" name="options" id="min" onChange={handleChange} /> 15 min
            </label>
            <label class="btn btn-secondary">
              <input type="radio" name="options" id="hr" onChange={handleChange} /> 1 hr
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <button className="btn btn-primary" color="primary" onClick={(e) => onSubmit(e)}>Load</button>
      </div>
    </form>
  </div>
);

export default ChartForm;
