function callLoginApi(username, password, callback) {
  fetch('/login', {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({
      username,
      password
    })
  })
    .then(response => {
      if (!response.ok)
        return callback(new Error('Invalid username and password'));
      else
        return response.json()
    })
    .then(json => {
      //check for success..
      if (json) {
        return callback(null, json);
      }
    });
}

const callLogoutApi = (callback) => {
  fetch('/logout', {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: "POST",
    body: JSON.stringify({})
  })
    .then(response => {
      if (!response.ok)
        return callback(new Error('Unknown error'));
      else
        return callback(undefined, response.json());
    });
}

const checkSessionApi = () => {
  return new Promise((resolve, reject) => {
    fetch('/api/v1/check-session', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        //console.log(response);
        if (!response.ok)
          return reject(new Error('Logged out/Unknown error'));
        else
          return resolve();
      });
  });
}

export { callLoginApi, callLogoutApi, checkSessionApi }