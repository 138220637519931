import React, { Component } from 'react';
import queryString from 'query-string';
import Chart from '../components/chart/Chart';
import ChartForm from '../components/chart/ChartForm';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chart: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    const chart = {
      title: values.title,
      xLabel: values.xLabel,
      yLabel: values.yLabel,
      metric: values.metric,
      endDate: values.endDate,
      beginDate: values.beginDate
    }

    this.setState({
      chart
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    console.log(name);

    let chart = Object.assign({}, this.state.chart);
    //console.log(event.target.value);
    chart[name] = value;
    this.setState({ chart });
  }

  onSubmit(event) {
    event.preventDefault();
    let chart = Object.assign({}, this.state.chart);
    console.log(chart);
  }

  render() {
    const { chart } = this.state;

    if (chart.metric) {
      return (
        <div>
          <h2>Chart</h2>
          <div className="row">
            <div className="col-sm-12">
              <Chart
                title={chart.title}
                xLabel={chart.xLabel}
                yLabel={chart.yLabel}
                metric={chart.metric}
                beginDate={chart.beginDate}
                endDate={chart.endDate} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ChartForm
                onSubmit={this.onSubmit}
                handleChange={this.handleChange}
                chart={chart} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <h2>loading</h2>
      )
    }
  };
};

export default Dashboard;