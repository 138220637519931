import React, { Component } from 'react';
import Combo from './Combo';
import LineChartCard from './LineChartCard';

const data = [
  {
    id: 2019,
    codes: [
      {
        id: 'AF088O1R',
        quota: 170,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[122, 52, 14, 4, 1, 1, 0, 0, 0, 1], 248, 100, 135],
            issued: [[42, 44, 13, 3, 1, 1, 0, 0, 0, 1]]
          }, {
            applied: [[4, 1], 3, 1, 2],
            issued: [[1, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[36, 6, 2], 43, 8, 21],
            issued: [[22, 6, 2]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[9, 3], 39, 6, 16],
            issued: [[9, 3], 13]
          }, {
            applied: [[7, 3], 29, 4, 5],
            issued: [[], 8]
          }]
        }]
      }, {
        id: 'AF088P1R',
        quota: 65,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[30, 5], 30, 17, 8],
            issued: [[30, 5], 19]
          }, {
            applied: [[], 0, 0, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[], 2, 3, 1],
            issued: [[], 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2], 26, 19, 6],
            issued: [[2], 7]
          }, {
            applied: [[1], 25, 6, 5],
            issued: [[], 1]
          }]
        }]
      }, {
        id: 'AF088P5R',
        quota: 95,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[53, 7, 3, 0, 1, 0, 1], 47, 16, 17],
            issued: [[44, 7, 3, 0, 1, 0, 1]]
          }, {
            applied: [[1], 1, 1],
            issued: [[1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[14, 1], 9, 6, 2],
            issued: [[13, 1], 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[8, 6, 4, 1], 24, 19, 9],
            issued: [[3, 6, 4, 1]]
          }, {
            applied: [[5, 0, 1, 1], 11, 16, 6],
            issued: [[5], 4]
          }]
        }]
      }, {
        id: 'AM088O1A',
        quota: 85,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[49, 8, 3, 1, 3, 0, 0, 0, 1], 109, 12, 7],
            issued: [[45, 8, 3, 1, 3, 0, 0, 0, 1]]
          }, {
            applied: [[3, 1], 12, 3],
            issued: [[2, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[3, 0, 1], 2, 2, 1],
            issued: [[2, 0, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[1, 1, 0, 1], 16, 3, 2],
            issued: [[1, 1, 0, 1], 9]
          }, {
            applied: [[1, 1], 9, 3, 2],
            issued: [[], 3, 1, 1]
          }]
        }]
      }, {
        id: 'AM088O1R',
        quota: 160,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[141, 124, 87, 61, 53, 31, 14, 4, 5, 4, 1, 3, 0, 0, 0, 0, 0, 0, 1, 1], 288, 201, 80],
            issued: [[0, 0, 0, 0, 47, 28, 14, 4, 5, 3, 1, 3, 0, 0, 0, 0, 0, 0, 1, 1]]
          }, {
            applied: [[17, 10, 3, 2, 4, 3, 1, 2, 0, 0, 0, 0, 1], 28, 7, 6],
            issued: [[0, 0, 0, 0, 4, 3, 1, 2, 0, 0, 0, 0, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[30, 19, 4, 6, 3, 1], 37, 20, 12],
            issued: [[0, 0, 0, 0, 1, 1]]
          }, {
            applied: [[2]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[34, 30, 16, 2, 3, 1], 13, 5, 12],
            issued: [[0, 2, 16, 2, 3, 1]]
          }, {
            applied: [[26, 16, 5, 1, 0, 1], 5, 5, 12],
            issued: [[2, 14]]
          }]
        }]
      }, {
        id: 'AF137L1R',
        quota: 75,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[38, 9, 1, 1], 136, 75, 26],
            issued: [[38, 9, 1, 1], 7]
          }, {
            applied: [[], 4, 2],
            issued: [[], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[4], 15, 12, 3],
            issued: [[4], 6, 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2], 5, 1],
            issued: [[2], 5]
          }, {
            applied: [[1]]
          }]
        }]
      },
      {
        id: 'AF137O1R',
        quota: 100,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[43, 7, 1, 0, 0, 1], 192, 32, 27],
            issued: [[43, 7, 1, 0, 0, 1], 27]
          }, {
            applied: [[], 3]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[9, 1], 19, 5, 3],
            issued: [[9, 1], 5]
          }, {
            applied: [[1]],
            issued: [[1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[0, 1], 3, 1],
            issued: [[0, 1], 2]
          }, {
            applied: [[], 1]
          }]
        }]
      },
      {
        id: 'AF137P1R',
        quota: 220,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[6], 19, 9, 22],
            issued: [[6], 3, 1]
          }, {
            applied: [[], 1, 1, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[4], 3, 2, 5],
            issued: [[4]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[], 1],
            issued: [[], 1]
          }]
        }]
      },
      {
        id: 'AF137P5R',
        quota: 100,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[0, 0, 2], 1, 13],
            issued: [[0, 0, 2], 1, 1]
          }, {
            applied: [[], 0, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[1, 0, 1]],
            issued: [[1, 0, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[], 0, 0, 1]
          }]
        }]
      }, {
        id: 'AM137O1R',
        quota: 75,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[80, 64, 59, 23, 7, 6, 2], 87, 44, 16],
            issued: [[0, 0, 9, 21, 7, 6, 2]]
          }, {
            applied: [[5, 4, 3, 1], 7, 2, 1],
            issued: [[0, 0, 1, 1]]
          }]
        }, {
          type: 'child',
          data: [{
            applied: [[7, 10, 5, 8, 2], 4, 0, 1],
            issued: [[0, 0, 0, 8, 2]]
          }, {
            applied: [[2], 0, 0, 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[13, 28, 23, 12, 0, 1], 7, 2, 1],
            issued: [[0, 0, 0, 10, 0, 1]]
          }, {
            applied: [[1, 4, 9, 2], 2, 2],
            issued: [[0, 0, 7]]
          }]
        }]
      }, {
        id: 'AM137P1R',
        quota: 200,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[49, 6, 2, 2, 0, 0, 0, 0, 1], 60, 23, 6],
            issued: [[49, 6, 2, 2, 0, 0, 0, 0, 1], 41, 17, 2]
          }, {
            applied: [[2, 1], 3, 2, 1],
            issued: [[2, 1], 1, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[3, 1], 9, 4],
            issued: [[3, 1], 5, 2]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[25, 13, 8, 7, 3], 51, 13, 14],
            issued: [[0, 12, 8, 7, 3]]
          }, {
            applied: [[8, 2, 2], 13, 3, 3],
            issued: [[8], 4, 1]
          }]
        }]
      }
    ]
  },
  {
    id: 2018,
    kill: [{
      id: '088',
      bucks: 112,
      does: 111,
      fawns: 6,
      totalHunters: 348
    }, {
      id: '137',
      bucks: 82,
      does: 55,
      fawns: 0,
      totalHunters: 321
    }],
    codes: [
      {
        id: 'AF088O1R',
        quota: 170,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[65, 55, 19, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1], 184, 84, 139],
            issued: [[26, 55, 19, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1]]
          }, {
            applied: [[0, 1], 1, 2, 5],
            issued: [[0, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[14, 10, 0, 2], 29, 12, 17],
            issued: [[13, 10, 0, 2], 5]
          }, {
            applied: [[0, 2], 1],
            issued: [[0, 2]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[9, 0, 1], 36, 7, 23],
            issued: [[9, 0, 1], 15]
          }, {
            applied: [[5], 14, 2, 8],
            issued: [[], 4, 1, 1]
          }]
        }]
      }, {
        id: 'AF088P1R',
        quota: 65,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[35, 6, 1], 34, 22, 2],
            issued: [[35, 6, 1], 9]
          }, {
            applied: [[], 1, 0, 1],
            issued: [[], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[2], 5, 3],
            issued: [[2], 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2], 18, 22, 8],
            issued: [[2], 7]
          }, {
            applied: [[], 8, 9, 3],
            issued: [[], 1]
          }]
        }]
      }, {
        id: 'AF088P5R',
        quota: 95,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[51, 9, 2, 1, 0, 0, 0, 0, 1], 71, 17, 18],
            issued: [[42, 9, 2, 1, 0, 0, 0, 0, 1]]
          }, {
            applied: [[], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[9, 4], 14, 3, 3],
            issued: [[9, 4], 4]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[3, 1, 2], 33, 26, 17],
            issued: [[3, 1, 2], 8]
          }, {
            applied: [[1, 1, 2], 23, 7, 6],
            issued: [[], 9]
          }]
        }]
      }, {
        id: 'AM088O1R',
        quota: 160,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[156, 94, 62, 41, 48, 44, 5, 11, 2, 2, 1, 2, 1], 317, 190, 60],
            issued: [[0, 0, 0, 0, 33, 44, 5, 11, 2, 2, 1, 1, 1]]
          }, {
            applied: [[17, 6, 2, 1, 3, 3, 1, 3, 1, 0, 1], 34, 10, 4],
            issued: [[0, 0, 0, 0, 2, 3, 1, 3, 1, 0, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[24, 5, 10, 4, 5, 6], 35, 21, 7],
            issued: [[0, 0, 0, 0, 3, 6]]
          }, {
            applied: [[1, 0, 1], 2]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[32, 33, 27, 10, 3, 1, 0, 0, 1], 5, 5, 6],
            issued: [[0, 0, 9, 10, 3, 1, 0, 0, 1]]
          }, {
            applied: [[15, 17, 16, 9, 1, 1, 0, 0, 1], 4, 4, 4],
            issued: [[0, 5, 11]]
          }]
        }]
      }, {
        id: 'AF137L1R',
        quota: 400,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[52, 6, 2, 2, 0, 0, 0, 0, 0, 1], 123, 57, 25],
            issued: [[52, 6, 2, 2, 0, 0, 0, 0, 0, 1], 99, 35, 8]
          }, {
            applied: [[3], 1, 1, 2],
            issued: [[3], 0, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[8, 1], 7, 9, 1],
            issued: [[8, 1], 5, 4]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[1, 3], 9, 1, 2],
            issued: [[1, 3], 3]
          }, {
            applied: [[1, 3], 5, 1, 2]
          }]
        }]
      },
      {
        id: 'AF137O1R',
        quota: 150,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[47, 10, 5, 2], 229, 35, 22],
            issued: [[47, 8, 5, 2], 51]
          }, {
            applied: [[3], 5, 3],
            issued: [[3], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[13, 2, 0, 1], 31, 7, 4],
            issued: [[13, 2, 0, 1], 6]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[0, 3], 10, 6, 6],
            issued: [[0, 3], 4, 4]
          }, {
            applied: [[0, 3], 6, 6, 6]
          }]
        }]
      }, {
        id: 'AF137P1R',
        quota: 220,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[5, 1, 0, 2], 24, 7, 25],
            issued: [[5, 1, 0, 2], 3]
          }, {
            applied: [[], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[2], 7, 0, 3],
            issued: [[2], 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[], 13, 1],
            issued: [[], 4]
          }, {
            applied: [[], 3, 1]
          }]
        }]
      }, {
        id: 'AM137O1R',
        quota: 100,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[101, 81, 58, 20, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1], 88, 34, 18],
            issued: [[0, 0, 41, 16, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1]]
          }, {
            applied: [[9, 4, 1, 3, 1], 6, 6, 5],
            issued: [[0, 0, 1, 3, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[27, 8, 11, 1], 9, 4, 1],
            issued: [[0, 0, 6, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[39, 55, 25, 6, 2, 0, 0, 0, 0, 1], 13, 4],
            issued: [[0, 0, 6, 6, 2, 0, 0, 0, 0, 1]]
          }, {
            applied: [[9, 29, 4], 9, 2],
            issued: [[0, 8, 2]]
          }]
        }]
      }, {
        id: 'AM137P1R',
        quota: 260,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[46, 7, 6, 2, 1, 2], 82, 30, 5],
            issued: [[46, 7, 6, 2, 1, 2], 53, 19, 1]
          }, {
            applied: [[17, 1], 5, 1, 2],
            issued: [[17, 1], 3, 0, 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[9, 1], 10, 2, 1],
            issued: [[9, 1], 9, 1]
          }, {
            applied: [[1]],
            issued: [[1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[21, 4, 4, 1, 1], 49, 11, 11, 0, 2],
            issued: [[21, 4, 4, 1, 1], 8]
          }, {
            applied: [[5, 1, 0, 0, 1], 10, 2, 0, 0, 2],
            issued: [[], 6, 1, 0, 0, 2]
          }]
        }]
      }
    ]
  }, {
    id: 2017,
    kill: [{
      id: '088',
      bucks: 109,
      does: 54,
      fawns: 4,
      totalHunters: 232
    }, {
      id: '137',
      bucks: 112,
      does: 68,
      fawns: 0,
      totalHunters: 338
    }],
    codes: [
      {
        id: 'AF088O1R',
        quota: 85,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[55, 57, 18, 9, 0, 2], 185, 79, 112],
            issued: [[0, 25, 16, 8, 0, 2]]
          }, {
            applied: [[], 1, 2, 2]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[23, 8, 2, 1], 17, 11, 13],
            issued: [[4, 7, 2, 1]]
          }, {
            applied: [[1], 2, 1]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[3], 44, 6, 28, 0, 3, 2],
            issued: [[3], 9]
          }, {
            applied: [[], 28, 0, 6, 0, 1],
            issued: [[], 8]
          }]
        }]
      }, {
        id: 'AF088P1R',
        quota: 50,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[34, 10, 0, 0, 1, 1], 38, 15, 6],
            issued: [[28, 10, 0, 0, 1, 1]]
          }, {
            applied: [[1], 1],
            issued: [[1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[0, 0, 0, 1], 5, 1],
            issued: [[0, 0, 0, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2], 12, 35, 5, 0, 0, 1],
            issued: [[2], 5]
          }, {
            applied: [[1], 0, 11, 1, 0, 0, 1],
            issued: [[], 0, 0, 0, 0, 0, 1]
          }]
        }]
      }, {
        id: 'AF088P5R',
        quota: 80,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[51, 2, 1, 1, 2, 1, 0, 0, 1], 46, 22, 11],
            issued: [[43, 2, 1, 1, 2, 1, 0, 0, 1]]
          }, {
            applied: [[], 2]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[14, 2, 0, 0, 0, 1], 11, 4, 2],
            issued: [[11, 2, 0, 0, 0, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[10], 22, 5, 14],
            issued: [[10], 2]
          }, {
            applied: [[], 4, 2, 6],
            issued: [[], 3]
          }]
        }]
      }, {
        id: 'AM088O1R',
        quota: 140,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[96, 68, 52, 48, 34, 28, 30, 24, 5, 6, 1, 2, 1], 251, 135, 60],
            issued: [[0, 0, 0, 0, 2, 27, 26, 24, 5, 6, 1, 2, 1]]
          }, {
            applied: [[0, 4, 0, 1, 2, 3, 0, 2, 0, 0, 2, 0, 0, 1], 10, 4, 4],
            issued: [[0, 0, 0, 0, 0, 3, 0, 2, 0, 0, 2, 0, 0, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[9, 8, 5, 4, 5, 2, 1], 22, 26, 4],
            issued: [[0, 0, 0, 0, 0, 2, 1]]
          }, {
            applied: [[0, 3], 0, 2, 2]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[34, 31, 14, 9, 11, 7, 0, 1], 9, 2, 1, 2],
            issued: [[0, 0, 0, 2, 11, 7, 0, 1]]
          }, {
            applied: [[9, 19, 9, 8, 8, 2, 0, 1], 2],
            issued: [[0, 0, 8, 6]]
          }]
        }]
      }, {
        id: 'AF137L1R',
        quota: 400,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[50, 7, 3, 0, 0, 0, 1], 126, 47, 29],
            issued: [[50, 7, 3, 0, 0, 0, 1], 108, 23, 12]
          }, {
            applied: [[], 1],
            issued: [[], 1]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[8, 1], 14, 7, 6],
            issued: [[8, 1], 7, 1, 4]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[], 11, 13, 1],
            issued: [[], 11]
          }, {
            applied: [[], 7, 12]
          }]
        }]
      }, {
        id: 'AF137O1R',
        quota: 150,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[52, 7], 212, 40, 32],
            issued: [[50, 7], 53]
          }, {
            applied: [[1], 5],
            issued: [[1], 2]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[9, 1], 34, 10, 1],
            issued: [[9, 1], 16]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2, 0, 0, 1], 10, 9, 1],
            issued: [[2, 0, 0, 1], 6, 1, 1]
          }, {
            applied: [[], 7, 8, 1]
          }]
        }]
      }, {
        id: 'AF137P1R',
        quota: 220,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[4], 16, 12, 23],
            issued: [[4], 4]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[3], 12, 2, 8],
            issued: [[3], 2]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[2], 6, 0, 3],
            issued: [[2], 3]
          }, {
            applied: [[], 0, 0, 3]
          }]
        }]
      }, {
        id: 'AM137O1R',
        quota: 100,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[111, 81, 50, 15, 9, 3, 1, 0, 0, 1], 121, 40, 7],
            issued: [[0, 0, 36, 15, 8, 3, 1, 0, 0, 1]]
          }, {
            applied: [[13, 3, 0, 1], 5, 0, 1],
            issued: [[0, 0, 0, 1]]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[22, 13, 7, 2, 4], 7, 5],
            issued: [[0, 0, 4, 2, 4]]
          }, {
            applied: [[1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[47, 28, 21, 8], 10, 4, 1, 2],
            issued: [[0, 0, 7, 8]]
          }, {
            applied: [[38, 9, 18, 6], 5, 4, 1, 2],
            issued: [[0, 0, 10]]
          }]
        }]
      }, {
        id: 'AM137P1R',
        quota: 260,
        draw: [{
          type: 'adult',
          data: [{
            applied: [[45, 10, 3, 0, 0, 1], 57, 27, 5],
            issued: [[45, 10, 3, 0, 0, 1], 45, 18, 2]
          }, {
            applied: [[10], 9, 4, 4],
            issued: [[10], 4]
          }]
        }, {
          type: 'youth',
          data: [{
            applied: [[13, 3, 1], 8, 9, 2],
            issued: [[13, 3, 1], 4, 5]
          }, {
            applied: [[2, 1]],
            issued: [[2, 1]]
          }]
        }, {
          type: 'lpp',
          data: [{
            applied: [[28, 10], 46, 10, 10, 0, 2],
            issued: [[28, 10], 1]
          }, {
            applied: [[4], 37, 2, 2, 0, 2],
            issued: [[], 25, 0, 0, 0, 1]
          }]
        }]
      }
    ]
  }
];

const harvestData = [{
  unit: 88,
  labels: ['2003', '2004', '2005', '2007', '2008', '2009', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
  bucks: [33, 37, 27, 42, 41, 48, 48, 42, 60, 67, 59, 54, 68, 79, 109, 112, 124],
  does: [12, 8, 8, 52, 49, 72, 72, 58, 63, 55, 55, 41, 85, 125, 85, 153, 137],
  fawns: [1, 2, 3, 0, 0, 10, 10, 19, 8, 6, 5, 7, 11, 7, 7, 13, 25],
  totals: [46, 47, 38, 94, 90, 130, 130, 119, 131, 128, 119, 102, 164, 211, 201, 278, 269]
}, {
  unit: 137,
  labels: ['2003', '2004', '2005', '2007', '2008', '2009', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
  bucks: [35, 44, 41, 29, 50, 38, 38, 37, 107, 99, 76, 73, 96, 89, 112, 82, 56],
  does: [55, 31, 50, 14, 44, 41, 41, 54, 64, 118, 60, 91, 99, 102, 98, 70, 46],
  fawns: [8, 12, 5, 2, 2, 7, 7, 7, 5, 0, 8, 16, 9, 4, 0, 8, 11],
  totals: [98, 87, 96, 45, 96, 86, 86, 98, 176, 217, 144, 180, 204, 195, 210, 160, 113]
}];

const popEstimate = [{
  unit: 88,
  labels: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
  estimate: [4200, 220, 5060, 5400, 7880, 7030, 7020, 7130, 7580, 7750, 8090, 7240, 6060, 7220, 6680]
}, {
  unit: 137,
  labels: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'],
  estimate: [2130, 1260, 1570, 1620, 2010, 2070, 4120, 3310, 3040, 2340, 2340, 3100, 3500, 3420, 3150]
}]

const getTotalLicences = (info) => {
  let total = 0;
  for (let i = 0; i < info.draw.length; i++) {
    for (let j = 0; j < info.draw[i].data.length; j++) {
      if (info.draw[i].data[j].issued) {
        total += info.draw[i].data[j].issued[0].reduce((acc, item) => acc + item, 0);
        for (let k = 1; k < info.draw[i].data[j].issued.length; k++) {
          total += info.draw[i].data[j].issued[k];
        }
      }
    }
  }
  return total;
}

const getSuccessData = (info, dataIndex) => {
  const typeCode = info.id.slice(-1);
  const unitNo = info.id.substring(2, 5);
  const similar = data[dataIndex].codes.filter((area) => {
    return area.id.indexOf(typeCode) >= 0 && area.id.indexOf(unitNo) >= 0
  });
  //seperate m and f
  const maleList = similar.filter((area) => area.id.indexOf("M") > 0);
  const femaleList = similar.filter((area) => area.id.indexOf("F") > 0);

  const maleTotal = maleList.reduce((acc, item) => acc + getTotalLicences(item), 0);
  const femaleTotal = femaleList.reduce((acc, item) => acc + getTotalLicences(item), 0);

  let harvest = [];
  let licence = [];
  let labels = [];

  let killData = harvestData.filter((harvest) => harvest.unit === parseInt(unitNo));
  //let killData = data[dataIndex].kill.filter((item) => item.id === unitNo)
  if (killData.length === 1) {
    killData = killData[0];
  } else {
    killData = undefined;
  }

  //check data index
  const killindex = killData.labels.length - dataIndex - 1;

  //need to know the year.
  harvest.push(killData.bucks[killindex]);
  licence.push(maleTotal);
  labels.push("Bucks");

  harvest.push(killData.does[killindex]);
  licence.push(femaleTotal);
  labels.push("Does");

  return [[{
    type: 'line',
    label: 'Licences',
    data: licence
  }, {
    type: 'bar',
    label: 'Harvested',
    data: harvest
  }], labels];
}

const getComboData = (info) => {
  let pre = [];
  let post = [];
  let labels = [];
  for (let i = 0; i < info.draw[0].data[0].applied[0].length; i++) {
    pre.push(info.draw[0].data[0].applied[0][i]);
    labels.push(`${i}`);
    if (info.draw[0].data[0].issued) {
      let acc = 0;
      if (i === 0 && info.draw[0].data[0].issued.length > 1) {
        for (let j = 1; j < info.draw[0].data[0].issued.length; j++) {
          acc += info.draw[0].data[0].issued[j];
        }
      }
      post.push(acc + info.draw[0].data[0].issued[0][i]);
    } else {
      post.push(0);
    }
  }
  return [[{
    type: 'line',
    label: 'Applied',
    data: pre
  }, {
    type: 'bar',
    label: 'Licence',
    data: post
  }], labels];
}

const getDataIndex = (year) => {
  let dataIndex = 0;
  if (year === 2018) {
    dataIndex = 1;
  } else if (year === 2017) {
    dataIndex = 2;
  }
  return dataIndex;
}

const getInfo = (unit, year) => {
  const dataIndex = getDataIndex(year);
  const info = data[dataIndex].codes.filter(item => item.id === unit);
  if (info.length === 1) {
    return info[0];
  } else {
    return {};
  }
}

class Antelope extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
    this.setUnit = this.setUnit.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
  }

  componentDidMount() {
    //load query string
    const query = new URLSearchParams(this.props.location.search);
    let unitQuery = query.get("unit") || "AF088O1R";
    this.setUnit(unitQuery);
  }

  setUnit(unit) {
    const info2019 = getInfo(unit, 2019);
    const info = getInfo(unit, 2018);
    const info2017 = getInfo(unit, 2017);

    //set the harvest data.
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    let color1 = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
    r = Math.floor(Math.random() * 255);
    g = Math.floor(Math.random() * 255);
    b = Math.floor(Math.random() * 255);
    let color2 = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
    r = Math.floor(Math.random() * 255);
    g = Math.floor(Math.random() * 255);
    b = Math.floor(Math.random() * 255);
    let color3 = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
    r = Math.floor(Math.random() * 255);
    g = Math.floor(Math.random() * 255);
    b = Math.floor(Math.random() * 255);
    let color4 = "rgba(" + r + ", " + g + ", " + b + ", 0.9)";
    const index = unit.indexOf('88') > 0 ? 0 : 1
    const harvest = [
      harvestData[index].labels,
      [{
        label: "Buck's",
        borderColor: color1,
        backgroundColor: color1,
        fill: false,
        data: harvestData[index].bucks
      }, {
        label: "Doe's",
        borderColor: color2,
        backgroundColor: color2,
        fill: false,
        data: harvestData[index].does
      }, {
        label: "Fawn's",
        borderColor: color3,
        backgroundColor: color3,
        fill: false,
        data: harvestData[index].fawns
      }, {
        label: "Total",
        borderColor: color4,
        backgroundColor: color4,
        fill: false,
        data: harvestData[index].totals
      }],
      {
        responsive: true,
        hover: {
          mode: 'nearest',
          intersect: true
        },
        stacked: false,
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Year",
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc"
            },
            gridLines: { color: "#cccccc" }
          }],
          yAxes: [{
            type: 'linear',
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Value",
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc",
              beginAtZero: true
            },
            gridLines: { color: "#666666" }
          }]
        }
      }
    ];

    const pop = [
      popEstimate[index].labels,
      [{
        label: "Estimate",
        borderColor: color1,
        backgroundColor: color1,
        fill: false,
        data: popEstimate[index].estimate
      }],
      {
        responsive: true,
        hover: {
          mode: 'nearest',
          intersect: true
        },
        stacked: false,
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Year",
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc"
            },
            gridLines: { color: "#cccccc" }
          }],
          yAxes: [{
            type: 'linear',
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Value",
              fontColor: "#cccccc"
            },
            ticks: {
              fontColor: "#cccccc",
              beginAtZero: true
            },
            gridLines: { color: "#666666" }
          }]
        }
      }
    ]

    this.setState({
      unit: unit,
      comboData: [getComboData(info2019), getComboData(info), getComboData(info2017)],
      harvestData: [getSuccessData(info2019, getDataIndex(2019)), getSuccessData(info, getDataIndex(2018)), getSuccessData(info2017, getDataIndex(2017))],
      harvest: harvest,
      pop: pop,
      dropdownOpen: false
    });
  }

  openDropdown() {
    const setOpen = !this.state.dropdownOpen
    this.setState({
      dropdownOpen: setOpen
    });
  }

  render() {
    const { unit, comboData, harvestData, harvest, pop, dropdownOpen } = this.state;

    let unitNo = "";
    if (unit && unit.length > 0) {
      unitNo = parseInt(unit.substring(2, 5));
    }

    if (comboData) {
      const dropdownClass = "dropdown" + (dropdownOpen ? " open" : "");
      return (
        <div className="row">
          <nav className="col-md-2 d-none d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
              <ul className="nav flex-column">
                {data[1].codes.map((area) => (
                  <li className="nav-item" key={area.id}>
                    <a
                      className={"nav-link" + (unit === area.id ? " active" : "")}
                      href={"?unit=" + area.id}
                    >{area.id}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <div class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
            <h2>Antelope</h2>
            <h3>{`Unit ${unit}`}</h3>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LineChartCard
                  title={"Harvest GMU " + unitNo}
                  datasets={harvest[1]}
                  labels={harvest[0]}
                  options={harvest[2]}
                  xLabel="Years"
                  yLabel="Count"
                  legend={true} />
              </div>
              <div className="col-sm-12 col-md-6">
                <LineChartCard
                  title="Population Estimate"
                  datasets={pop[1]}
                  labels={pop[0]}
                  options={pop[2]}
                  xLabel="Years"
                  yLabel="Count"
                  legend={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Combo
                  title="Points 2019"
                  data={comboData[0][0]}
                  labels={comboData[0][1]}
                  xLabel="Points"
                  yLabel="Count"
                  legend={true} />
              </div>
              <div className="col-sm-12 col-md-6">
                <Combo
                  title={"Harvest GMU " + unitNo + " (Includes Private Land) Rife Only 2019"}
                  data={harvestData[0][0]}
                  labels={harvestData[0][1]}
                  xLabel="Sex"
                  yLabel="Count"
                  legend={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Combo
                  title="Points 2018"
                  data={comboData[1][0]}
                  labels={comboData[1][1]}
                  xLabel="Points"
                  yLabel="Count"
                  legend={true} />
              </div>
              <div className="col-sm-12 col-md-6">
                <Combo
                  title={"Harvest GMU " + unitNo + " (Includes Private Land) Rife Only 2018"}
                  data={harvestData[1][0]}
                  labels={harvestData[1][1]}
                  xLabel="Sex"
                  yLabel="Count"
                  legend={true} />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Combo
                  title="Points 2017"
                  data={comboData[2][0]}
                  labels={comboData[2][1]}
                  xLabel="Points"
                  yLabel="Count"
                  legend={true} />
              </div>
              <div className="col-sm-12 col-md-6">
                <Combo
                  title={"Harvest GMU " + unitNo + " (Includes Private Land) Rife Only 2017"}
                  data={harvestData[2][0]}
                  labels={harvestData[2][1]}
                  xLabel="Sex"
                  yLabel="Count"
                  legend={true} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>loading</div>
      )
    }
  }
};

export default Antelope;