import * as types from './actionTypes';
import { getLeftovers } from '../service/dow';

export function loadLeftovers() {
  return function (dispatch) {
    return getLeftovers()
      .then(leftovers => {
        dispatch(loadLeftoverSuccess(leftovers));
      }).catch(error => {
        throw (error);
      });
  };
}

export function loadLeftoverSuccess(leftovers) {
  return { type: types.LOAD_DOW_LEFTOVER_SUCCESS, leftovers };
}
