export const LOAD_SECRETS_SUCCESS = 'LOAD_SECRETS_SUCCESS';
export const UPDATE_SECRETS_SUCCESS = 'UPDATE_SECRETS_SUCCESS';
export const CREATE_SECRETS_SUCCESS = 'CREATE_SECRETS_SUCCESS';
export const DELETE_SECRETS_SUCCESS = 'DELETE_SECRETS_SUCCESS';

export const LOAD_BLOGS_SUCCESS = 'LOAD_BLOGS_SUCCESS';
export const LOAD_BLOG_DETAILS_SUCCESS = "LOAD_BLOG_DETAILS_SUCCESS";
export const CREATE_BLOG_SUCCESS = 'CREATE_BLOG_SUCCESS';
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS';

export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';

export const LOAD_SERIES_SUCCESS = 'LOAD_SERIES_SUCCESS';
export const UPDATE_SERIES_SUCCESS = 'UPDATE_SERIES_SUCCESS';
export const CREATE_SERIES_SUCCESS = 'CREATE_SERIES_SUCCESS';
export const DELETE_SERIES_SUCCESS = 'DELETE_SERIES_SUCCESS';

export const LOAD_BLOG_CATEGORIES_SUCCESS = 'LOAD_BLOG_CATEGORIES_SUCCESS';

export const LOAD_PIHOME_SUCCESS = 'LOAD_PIHOME_SUCCESS';
export const UPDATE_PIHOME_SUCCESS = 'UPDATE_PIHOME_SUCCESS';
export const LOAD_STATUS_PIHOME = 'LOAD_STATUS_PIHOME';

export const LOAD_DOW_LEFTOVER_SUCCESS = 'LOAD_DOW_LEFTOVER_SUCCESS';

export const LOAD_STREAM_SUCCESS = 'LOAD_STREAM_SUCCESS';