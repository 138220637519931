import React from 'react';
import { Link } from "react-router-dom";

const PreviewAlbum = ({ album }) => (
  <div className="col-sm-3 pad-top" key={album.id}>
    <div className="card">
      <div className="card-body">
        <Link to={`/photo-share/${album.id}`}><h5 className="card-title" >{album.title}</h5></Link>
        <h6>{album.description}</h6>
        <p className="card-text">Number of photos: {album.items.length}</p>
        <p className="card-text"><small className="text-muted">{album.date.toLocaleDateString()}</small></p>
      </div>
      <div className="card-footer">
        <a href={album.gamesheets}>Gamesheets Stats</a>
      </div>
    </div>
  </div>
);

export default PreviewAlbum;