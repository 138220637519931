import React, { Component } from 'react';
import LineChart from './LineChart';
import { connect } from 'react-redux';

const timeFormat = 'MM/DD/YYYY h:mm A';

class Chart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      title: props.title,
      xLabel: props.xLabel,
      yLabel: props.yLabel,
      beginDate: props.beginDate,
      endDate: props.endDate,
      metric: props.metric,
      source: props.source,
      steppedLine: props.steppedLine
    };
  }

  componentDidMount() {
    this.loadChart();
  }

  componentDidUpdate(nextProps) {
    const { xLabel, yLabel, metric, beginDate, endDate, source } = this.props
    if (nextProps.xLabel !== xLabel || nextProps.yLabel !== yLabel) {
      this.loadChart();
    } else if (nextProps.metric !== metric || nextProps.beginDate !== beginDate ||
      nextProps.endDate !== endDate || nextProps.source !== source) {
      this.loadChart();
    }
  }

  loadChart() {
    let URL = `/api/v1/metrics?m=${encodeURIComponent(this.props.metric)}&b=${this.props.beginDate}&e=${this.props.endDate}`;
    // make the source optional.
    if (this.props.source) {
      URL += `&s=${encodeURIComponent(this.props.source)}`;
    }

    fetch(URL, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "GET"
    })
      .then(response => {
        if (!response.ok)
          return Promise.reject(new Error('error'));
        else
          return response.json()
      })
      .then(rtnData => {
        //check for success..
        if (rtnData) {
          //convert date into date objects
          for (let i = 0; i < rtnData.length; i++) {
            rtnData[i].date = new Date(rtnData[i].date);
            rtnData[i].value = parseFloat(rtnData[i].value.toFixed(2));
          }

          //find if there is more than 1 source, if so find if there is more than 1 tag for that source.
          const counts = rtnData.reduce((acc, item) => {
            if (!acc.sources[item.source]) {
              acc.sources[item.source] = [];
              acc.sourceCount = acc.sourceCount + 1;
            }
            //check if the tag is in there.
            if (acc.sources[item.source].filter(filterItem => {
              for (let propName in item.tags) {
                if (!filterItem[propName] || filterItem[propName] !== item.tags[propName]) {
                  return false;
                }
              }
              return true;
            }).length === 0) {
              acc.sources[item.source].push(item.tags);
            }
            return acc;
          }, {
            sources: {},
            sourceCount: 0
          })
          let data = {};
          //find out if group by source, group by tag, or group by both.
          let groupTag = false;
          for (let propName in counts.sources) {
            if (counts.sources[propName].length > 1) {
              groupTag = true;
            }
          }

          if (counts.sourceCount === 1 && groupTag) {
            for (let i = 0; i < rtnData.length; i++) {
              //split the lines in the chart based on tags
              let propName = "";
              for (let tagPropName in rtnData[i].tags) {
                propName += rtnData[i].tags[tagPropName];
              }
              if (!data[propName]) {
                data[propName] = [];
              }
              //format the x and y
              data[propName].push({
                x: rtnData[i].date,
                y: rtnData[i].value
              });
            }
          } else {
            for (let i = 0; i < rtnData.length; i++) {
              let propName = rtnData[i].source;
              if (groupTag) {
                for (let tagPropName in rtnData[i].tags) {
                  propName += '-' + rtnData[i].tags[tagPropName];
                }
              }
              //split the lines in the chart based on source
              if (!data[propName]) {
                data[propName] = [];
              }
              //format the x and y
              data[propName].push({
                x: rtnData[i].date,
                y: rtnData[i].value
              });
            }
          }

          //get the labels and data in correct format
          const labels = rtnData.map(item => item.date)

          //default for steppedLine
          let steppedLine = false;
          if (this.props.steppedLine) {
            steppedLine = this.props.steppedLine
          }

          //create the datasets.
          let datasets = [];
          for (let propName in data) {
            let r = Math.floor(Math.random() * 255);
            let g = Math.floor(Math.random() * 255);
            let b = Math.floor(Math.random() * 255);
            let color = "rgba(" + r + ", " + g + ", " + b + ", 0.5)";
            //print out color so we can get a set of colors to use in charts.
            console.log({
              metric: this.props.metric,
              color,
              propName
            });
            if (data[propName].length > 0) {
              datasets.push({
                label: propName,
                data: data[propName],
                backgroundColor: color,
                borderColor: color,
                steppedLine: steppedLine,
                fill: false
              });
            }
          }

          this.setState({
            isLoaded: true,
            labels: labels,
            datasets: datasets,
            options: {
              //responsive: true,
              title: {
                text: this.props.title
              },
              scales: {
                xAxes: [{
                  type: 'time',
                  time: {
                    parser: timeFormat,
                    // round: 'day'
                    tooltipFormat: 'll h:mm A'
                  },
                  scaleLabel: {
                    display: true,
                    labelString: this.props.xLabel
                  }
                }],
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: this.props.yLabel
                  }
                }]
              }
            }
          });
        } else {
          this.setState({
            isLoaded: true,
            error: true
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          error: true
        });
      });
  }

  render() {
    const { isLoaded, labels, datasets, options, xLabel, yLabel } = this.state;
    const { title } = this.props;

    if (!isLoaded) {
      return (
        <div>
          <span className="spinner spinner-inverse spinner-sm">
            Loading...
          </span>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-block">
            <div className="card-text">
              <LineChart
                labels={labels}
                datasets={datasets}
                title={title}
                options={options}
                xLabel={xLabel}
                yLabel={yLabel}
                legend={true} />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Chart;
