import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class BlogList extends Component {
  render() {
    const { series } = this.props;
    //console.log(series);
    return (
      <div>
        <div className="App-header">
          <h2>Blog Posts</h2>
        </div>
        <p></p>
        <ul>
          {series.map((item) => (
            <li key={item.seriesCreated}>
              {item.series}
              <ul>
                {item.blogs.map((blog) => (
                  <li key={blog.id}>
                    <small className="text-muted">{blog.modified.toLocaleDateString()}</small> - <Link to={`blog/${blog.title}`}>{blog.title}</Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

/*BlogList.propTypes = {
  blogs: PropTypes.array.isRequired
};*/

function mapStateToProps(state) {
  //transfer the blogs array into what we need for display.
  const blogs = [...state.blogs];
  blogs.sort((a, b) => {
    const diff = b.seriesCreated.getTime() - a.seriesCreated.getTime();
    if (diff === 0) {
      //sub sort on created.
      return a.created.getTime() - b.created.getTime();
    } else {
      return diff;
    }
  });
  const blogList = [];
  for (let i = 0; i < blogs.length; i++) {
    const blog = { ...blogs[i] };
    const seriesList = blogList.filter(series => series.seriesCreated.getTime() === blog.seriesCreated.getTime());
    if (seriesList.length === 1) {
      //found it.
      seriesList[0].blogs.push(blog);
    } else {
      blogList.push({
        series: blog.series,
        seriesCreated: blog.seriesCreated,
        //series_id: blog.series_id,
        blogs: [blog]
      });
    }
  }
  return {
    series: blogList
  };
}

export default connect(mapStateToProps)(BlogList);
