import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import BlogForm from './BlogForm';
import { connect } from 'react-redux';
import * as seriesActions from '../../actions/seriesActions';
import * as blogActions from '../../actions/blogActions';
import './Blog.css';

class CreateBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      categories: [],
      blog: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log(name);

    let blog = Object.assign({}, this.state.blog);
    //console.log(event.target.value);
    blog[name] = value;
    this.setState({ blog });
  }

  onSubmit(event) {
    event.preventDefault();
    let blog = Object.assign({}, this.state.blog);
    blog.series_id = parseInt(blog.series_id);
    //console.log(blog);
    this.props.blogActions.createBlog(blog);
  }

  render() {
    return (
      <div>
        <div className="App-header">
          <h2>Create Blog</h2>
        </div>
        <p></p>
        <BlogForm
          onSubmit={this.onSubmit}
          handleChange={this.handleChange}
          categories={this.props.categories}
          series={this.props.series}
          blog={{}} />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    series: state.series,
    categories: state.blogCategories
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(seriesActions, dispatch),
    blogActions: bindActionCreators(blogActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBlog);
