import React from 'react';
import LoginForm from './LoginForm';

const Login = () => (
  <div>
    <h3 className="App-header">Login</h3>
    <div className="row justify-content-center">
      <div className="col">
        <LoginForm />
      </div>
    </div>
  </div>
);

export default Login;
